
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsMediclub3Block11',

  mixins: [cmsBlock],

  data () {
    return {
      open: [],
      fallbackContent: {
        header: 'Q&A',
        subheader: 'Poznaj odpowiedzi na najczęściej zadawane pytania',
        items: [
          {
            q: 'Vestibulum quis eros scelerisque, consequat nulla ultrices, blandit augue. ',
            a: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus tincidunt pellentesque ultricies. Sed finibus commodo ex, ac posuere justo. Pellentesque quis placerat risus. Suspendisse at mi id mi rhoncus vulputate. In nisi nulla, luctus at dui at, maximus molestie sapien. Pellentesque ut tempor nibh. Vivamus nec facilisis turpis, in malesuada tortor. Etiam vel erat ac massa molestie egestas. Nam justo mauris, rutrum eu dignissim nec, scelerisque quis neque. Sed efficitur ac justo id bibendum. Morbi gravida enim eget leo porttitor, at iaculis nulla imperdiet. Sed eget ligula id ligula efficitur luctus. Duis ut tellus quam.',
          },
          {
            q: 'Fusce turpis eros, consectetur eget pretium eget',
            a: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus tincidunt pellentesque ultricies. Sed finibus commodo ex, ac posuere justo. Pellentesque quis placerat risus. Suspendisse at mi id mi rhoncus vulputate. In nisi nulla, luctus at dui at, maximus molestie sapien. Pellentesque ut tempor nibh. Vivamus nec facilisis turpis, in malesuada tortor. Etiam vel erat ac massa molestie egestas. Nam justo mauris, rutrum eu dignissim nec, scelerisque quis neque. Sed efficitur ac justo id bibendum. Morbi gravida enim eget leo porttitor, at iaculis nulla imperdiet. Sed eget ligula id ligula efficitur luctus. Duis ut tellus quam.',
          },
          {
            q: 'Nullam metus tortor, semper commodo felis ut, bibendum ullamcorper ero',
            a: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus tincidunt pellentesque ultricies. Sed finibus commodo ex, ac posuere justo. Pellentesque quis placerat risus. Suspendisse at mi id mi rhoncus vulputate. In nisi nulla, luctus at dui at, maximus molestie sapien. Pellentesque ut tempor nibh. Vivamus nec facilisis turpis, in malesuada tortor. Etiam vel erat ac massa molestie egestas. Nam justo mauris, rutrum eu dignissim nec, scelerisque quis neque. Sed efficitur ac justo id bibendum. Morbi gravida enim eget leo porttitor, at iaculis nulla imperdiet. Sed eget ligula id ligula efficitur luctus. Duis ut tellus quam.',
          },
          {
            q: 'Integer efficitur tellus vitae est tempus congue. Integer at mi quam',
            a: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus tincidunt pellentesque ultricies. Sed finibus commodo ex, ac posuere justo. Pellentesque quis placerat risus. Suspendisse at mi id mi rhoncus vulputate. In nisi nulla, luctus at dui at, maximus molestie sapien. Pellentesque ut tempor nibh. Vivamus nec facilisis turpis, in malesuada tortor. Etiam vel erat ac massa molestie egestas. Nam justo mauris, rutrum eu dignissim nec, scelerisque quis neque. Sed efficitur ac justo id bibendum. Morbi gravida enim eget leo porttitor, at iaculis nulla imperdiet. Sed eget ligula id ligula efficitur luctus. Duis ut tellus quam.',
          },
        ],
      },
    }
  },

  methods: {
    toggle (index) {
      this.open = this.open.includes(index)
        ? this.open.filter(el => el !== index)
        : [...this.open, index]
    },
  },
}
