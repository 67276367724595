
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsMediclub3Menu',
  mixins: [cmsBlock],

  data () {
    return {
      isOpen: false,
      fallbackContent: {
        items: [
          {
            text: 'about',
            to: '.mediclub-page',
          },
          {
            text: 'fixed',
            to: '.medi-block-3',
          },
          {
            text: 'obszary tematycze',
            to: '.medi-block-4',
          },
          {
            text: 'limitowane',
            to: '.medi-block-5',
          },
          {
            text: 'otrzymaj...',
            to: '.medi-block-6',
          },
          {
            text: 'czytaj',
            to: '.medi-block-7',
          },
          {
            text: 'q&a',
            to: '.medi-block-11',
          },
        ],
      },
    }
  },

  methods: {
    goTo (selector) {
      const el = document.querySelector(selector)
      const menu = document.querySelector('.medi-block-menu')

      if (el && menu) {
        const pos = el.getBoundingClientRect()
        const menuSize = menu.getBoundingClientRect()

        window.scrollTo({
          top: pos.top + window.scrollY - menuSize.height,
          behavior: 'smooth',
        })
      }
    },

    onClick () {
      this.isOpen = !this.isOpen
    },
  },
}
