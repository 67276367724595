
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsMediclub3Block5',
  mixins: [cmsBlock],

  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },

    activeIndex: {
      type: Number,
      default: 0,
    },
  },

  data () {
    return {
      active: 0,
      pagination: {},
      fallbackContent: {
        title: 'Limited benefits',
        subtitle: 'Be the first and take advantage of offers that never come back',
        btn: {
          text: 'Unlock benefits',
          to: '/',
        },

        allBtnTxt: 'All benefits',
        showMore: 'Show more',
        itemCategoryUrl: 'mediclub-oferty/pazdziernik-2024',
        activeSlugs: ['swiatowy-dzien-walki-z-otyloscia', 'dbaj-o-oczy'],
      },
    }
  },

  computed: {
    benefitMenu () {
      return [this.blockContent.allBtnTxt, ...this.items.map(({ key }) => key)].filter(Boolean)
    },

    page () {
      return this.pagination[this.active] || 1
    },

    allItems () {
      return this.items.flatMap(b => b.blocks)
    },

    isLastPage () {
      return (this.active ? this.items[this.active - 1].blocks.length : this.allItems.length) / 8 <= this.page
    },
  },

  created () {
    this.active = this.activeIndex || 0
  },

  methods: {
    setActive (index) {
      this.active = index
    },

    nextPage () {
      this.$set(this.pagination, this.active, this.page + 1)
    },
  },
}
