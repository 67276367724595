export default async function mediclubProductLoader ({ store, app }, blockName = '') {
  let limitedBenefitItems = []
  let activeIndex = 0
  const blockData = store.getters['staticBlock/GET_BLOCKS'](blockName)
  const categoryUrl = blockData?.[blockName]?.contentJson?.itemCategoryUrl

  // categoryUrl = 'mediclub-oferty/pazdziernik-2024'

  if (categoryUrl) {
    const urlKeyArr = categoryUrl.split('/')
    const categories = store.getters['storeConfig/GET_CATEGORIES']

    let currentCategory = categories?.find(({ urlKey }) => urlKey === urlKeyArr[0]) || {}

    urlKeyArr.shift()

    urlKeyArr.filter(key => key !== '').forEach((element) => {
      currentCategory = JSON.parse(JSON.stringify(currentCategory?.children.find(item => item.urlKey === element))) || {}
    })

    // czy mamy liste sortowania/aktywnych elementow
    const activeSlugs = blockData?.[blockName]?.contentJson?.activeSlugs || []

    if (activeSlugs.length) {
      currentCategory.children = currentCategory.children?.filter(({ urlKey }) => activeSlugs.includes(urlKey))
      activeIndex = 1
    }

    // lista kategorii jako chips
    limitedBenefitItems = currentCategory.children?.map(child => ({
      uid: child.uid,
      key: child.name,
    }))

    await app.$graphql({
      requestPayload: {
        query: (await import('~/graphql/mediclub/queries/getProductForMediclub.graphql')).default,
        variables: {
          categoryId: currentCategory.uid,
        },
        useGETForQueries: true,
        context: {
          fetchOptions: {
            method: 'GET',
          },
        },
      },
      successHandler ({ data: { products: { items } } }) {
        limitedBenefitItems.forEach((c) => {
          c.blocks = items
            .filter(item => item.categories.find(ic => ic.uid === c.uid))
            .map(item => ({
              title: item.name,
              img: item.image.url,
              text: item.shortDescription.html,
              to: app.localePath(`/p/${item.urlKey}`),
              priceRange: item.priceRange,
            }))
        })
      },
    })
  }

  return { limitedBenefitItems, activeIndex }
}
