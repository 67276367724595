
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsMediclub3Block10',
  mixins: [cmsBlock],

  data () {
    return {
      fallbackContent: {
        header: 'Our partners',
        slides: [
          {
            logo: 'https://www.medicover.pl/Themes/Default/Images/logo-medicover.svg',
            url: 'http://medicover.pl/',
          },
          {
            logo: 'https://mindhealth.pl/wp-content/uploads/2023/08/logo-mh-nowe-1.jpg',
            url: 'https://mindhealth.pl/',
          },
          {
            logo: 'https://badanie-nasienia.pl/wp-content/uploads/2020/08/logo-invimed-new-1.png',
            url: 'https://invimed.pl/',
          },
          {
            logo: 'https://www.sklep.damian.pl/logo/damian-logo.svg',
            url: 'https://www.sklep.damian.pl/',
          },
          {
            logo: 'https://www.store.synevo.pl/logo/synevo-logo.svg',
            url: 'https://www.store.synevo.pl/',
          },
          {
            logo: 'https://sklep.rehasport.pl/logo/rehasport-logo.svg',
            url: 'https://sklep.rehasport.pl/',
          },
          {
            logo: 'https://www.medicovervital.pl/logo/medicoverapteka-logo.svg',
            url: 'https://www.medicovervital.pl/',
          },
        ],
      },
    }
  },
}
