export default {
  data () {
    return {
      scrollY: 0,
    }
  },

  activated () {
    window?.scrollTo({
      top: this.scrollY,
      behavior: 'instant',
    })

    window?.addEventListener('scroll', this.setScrollY)
  },

  deactivated () {
    window?.removeEventListener('scroll', this.setScrollY)
  },

  methods: {
    setScrollY () {
      this.scrollY = window?.scrollY
    },
  },
}
