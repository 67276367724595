
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsMediclub3Block9',
  mixins: [cmsBlock],

  data () {
    return {
      fallbackContent: {
        title: 'Three simple steps and endless benefits',
        img: '/mc/v3/block9/woman.png',
        steps: [
          {
            ico: '/mc/v3/block9/icon1.svg',
            title: 'Step 1',
            text: 'Create an account using your e-mail',
          },
          {
            ico: '/mc/v3/block9/icon2.svg',
            title: 'Step 2',
            text: 'Activate your account by clicking the link in the email you receive',
          },
          {
            ico: '/mc/v3/block9/icon3.svg',
            title: 'Step 3',
            text: 'Log in to the website, enjoy privileges and recommend us to others and receive valuable bonuses.',
          },
        ],
      },
    }
  },
}
