
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsMediclub3Block6',
  mixins: [cmsBlock],

  props: {
    isSport: { type: Boolean, default: false },
  },

  data () {
    return {
      fallbackContent: {
        header: 'Get your',
        subheader: 'personalised results',
        text1: 'First step to improve your daily lifestyle and see how you compare to others',
        text2: 'Take advantage of the free Self Care Program and find out what you can do to be well, feel good and enjoy life.',
        cards: [{
          disabled: false,
          text: 'Find out what is your overall health condition and get personalised recommendation plan',
          title: 'Your ',
          title2: 'General Health',
          img1: '/mc/v3/block6/icon1.svg',
          img2: '/mc/v3/block6/iconLock.svg',
          buttonText: 'Check',
          to: '/link/po/zalogowaniu',
        },
        {
          disabled: false,
          text: 'Find out what is your overall physical activity and what to do to learn good sport habits',
          title: 'Your ',
          title2: 'General Health',
          img1: '/mc/v3/block6/icon2.svg',
          img2: '/mc/v3/block6/iconLock.svg',
          buttonText: 'Check',
          to: '/link/po/zalogowaniu',
        },
        {
          disabled: false,
          text: 'Find out what is your overall health condition and get personalised recommendation plan',
          title: 'Your ',
          title2: 'Mental Health',
          img1: '/mc/v3/block6/icon3.svg',
          img2: '/mc/v3/block6/iconLock.svg',
          buttonText: 'Check',
          to: '/link/po/zalogowaniu',
        }],
      },
    }
  },
}
