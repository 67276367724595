
import cmsBlock from '~/mixins/cmsBlock'

export default {
  name: 'CmsMediclub3Block8',
  mixins: [cmsBlock],

  data () {
    return {
      active: 0,
      fallbackContent: {
        title: 'Over 350,000  satisfied Members all over the world',
        subtitle: 'See how much you can gain thanks to MediClub ',
        image: '/mc/v3/block8/ppl.png',
        text1: 'You get an access to tools allowing you self-development and give you many benefits that improve your well-being, life expectancy and budget savings. Only with MediClub you will have an access to your purchase history and status of delivery',
        text2: 'This program allows you to take care of your health and your family in a comprehensive way.',
      },
    }
  },
}
